import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import PageBanner from "../components/Common/PageBanner";
import Layout from "../timewise-custom/components/App/Layout";
import Navbar from "../timewise-custom/components/App/Navbar";
import SEO from "../timewise-custom/components/App/SEO";

const ContactSent = () => {
  const { t } = useI18next();

  return (
    <Layout>
      <SEO
        title={t("navigation:contact")}
        meta={{
          name: "robots",
          content: "noindex, nofollow, noimageindex",
        }}
      />
      <Navbar />
      <PageBanner
        pageTitle={t("navigation:contact")}
        homePageText={t("navigation:home")}
        homePageUrl="/"
        activePageText={t("navigation:contact")}
      />
      <section className="container py-5">
        <div className="alert alert-success" role="alert">
          <h2 class="alert-heading">{t("contact:success-title")}</h2>
          <p>{t("contact:success-text")}</p>
        </div>
      </section>
    </Layout>
  );
};

export default ContactSent;
